import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  type CmsBlockMenuItemsInner,
  CmsComponentMenuActionSchemaEnum,
  CmsComponentMenuSpacerSchemaEnum,
} from '@redteclab/api/clients/content-management-system'

import { Link } from 'base-ui'

import {
  headerCmsBlockMenuAccessorGetNavigationMenuData,
  type HeaderDataType,
} from '../../../standard-shop-layout'

/**
 * @example
 * ['menuCategories1', 'menuCategories2', 'spacer', 'menuCategories3']
 * returns
 * ['menuCategories1', 'menuCategories2']
 */
const getMenuItemsBeforeSpacer = (
  items: CmsBlockMenuItemsInner[],
): CmsBlockMenuItemsInner[] => {
  const spacerIndex = items.findIndex(
    (item) =>
      item.schema === CmsComponentMenuSpacerSchemaEnum.CmsComponentMenuSpacer,
  )

  return items.slice(0, spacerIndex)
}

export const ErrorPageCategories: FC<{ headerData: HeaderDataType }> = ({
  headerData,
}) => {
  const menuItems = headerCmsBlockMenuAccessorGetNavigationMenuData(
    headerData.cmsMenu,
  )
  const menuItemsBeforeSpacer = getMenuItemsBeforeSpacer(menuItems)

  return (
    <div>
      <div className="mb-6 text-l font-bold text-dark-primary-strong">
        <FormattedMessage id="errorPages.404.categories.headline" />
      </div>
      {menuItemsBeforeSpacer.map((menuItem) => {
        if (
          menuItem.schema ===
          CmsComponentMenuActionSchemaEnum.CmsComponentMenuAction
        ) {
          return (
            <Link
              className="flex items-center whitespace-nowrap no-underline"
              href={menuItem.href}
              key={menuItem.title}
            >
              {menuItem.title}
            </Link>
          )
        }

        return null
      })}
    </div>
  )
}
