'use client'

import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  type StandardLayoutProps,
  StandardShopLayout,
} from '../../standard-shop-layout'

import { ErrorPageCategories } from './ErrorPageCategories/ErrorPageCategories'

type ErrorPageProps = {
  standardLayoutData: StandardLayoutProps
}

export const ErrorPage: FC<ErrorPageProps> = ({ standardLayoutData }) => (
  <StandardShopLayout standardLayoutData={standardLayoutData}>
    <div className="container flex flex-col gap-16 py-28">
      <div className="flex flex-col items-center">
        <svg
          className="mb-8 fill-dark-brand"
          height={120}
          viewBox="0 0 100 100"
          width={120}
        >
          <path d="M17.6554 99.9998C27.5424 99.9998 35.3112 92.1531 35.3112 82.1672C35.3112 72.1813 27.5424 64.3343 17.6554 64.3343C7.76873 64.3343 0 72.1813 0 82.1672C0 92.1531 7.76873 99.9998 17.6554 99.9998ZM19.9184 13.8329C12.2944 15.5471 7.34772 20.251 7.34772 28.5297C7.34772 36.8016 12.2944 41.3661 19.9184 43.2265L43.6455 49.0732C48.1645 50.2157 50.2828 52.0696 51.2695 56.634L56.7753 80.7455C58.4724 88.4457 62.9914 93.4419 71.3261 93.4419C79.6604 93.4419 84.1795 88.4457 85.8769 80.7455L99.0132 20.9685C99.5788 18.258 100 16.2581 100 14.2648C100 5.99293 93.3627 0 85.1662 0C83.1862 0 81.2127 0.285689 79.0949 0.710918L19.9184 13.8329Z" />
        </svg>
        <h1 className="text-3xl font-medium">
          <FormattedMessage id="errorPages.404.headline" />
        </h1>
        <p className="mt-6 text-l font-medium text-dark-primary-medium">
          <FormattedMessage id="errorPages.404.label" />
        </p>
      </div>
      <ErrorPageCategories headerData={standardLayoutData.headerData} />
    </div>
  </StandardShopLayout>
)
